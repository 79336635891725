.cover-box {
    display: block;
    float: left;
    position: relative;
    .easing-animation();

    &.normal-style {
        .cover-box-content {
            display: block;
        }
    }

    &:not(.normal-style) {
        .cover-box-content {
            display: block;
            position: absolute;
            .pos(0,100%);
            .square(100%);
        }
    }
}

.cover-box-container {
    overflow: hidden;
    position: relative;
}

// Cover Box (Short Code)
.cover-box-shortcode {
    .cover-box {
        background-color: @bgc-light;
        margin-bottom: 30px;
        padding-left: 15px;
        padding-right: 15px;

        .cover-box-content {
            padding-left: 15px;
            padding-right: 15px;

            .content-wrapper {

                h5 {
                    font-size: @fz-heading-5 + 2px;
                    line-height: 1.2;
                    margin-bottom: 18px;
                }

                h6 {
                    font-size: @fz-heading-6 + 1px;
                    font-weight: 600;
                    line-height: 1.2;
                    margin-bottom: 18px;
                    .color-main;
                    .font-type-3();
                }

                p {
                    margin-bottom: 22px;
                }
            }
        }

        img {
            width: 100%;
        }
    }

    @media (max-width: 784px) {

        .cover-box {

            > div {
                margin-right:auto;
                margin-left:auto;
                max-width:370px;
            }

            .content-wrapper {
                padding-top:45px;
                padding-bottom:45px;
            }
        }
    }
}