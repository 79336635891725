.blockquotes-wrapper {
    margin: 90px 0;
    p,
    h4 {
        margin-bottom:0;
    }

    .blockquotes {
        padding:40px 0 40px 35px;

        h4 {
            .font-serif;

            display:inline-block;
            line-height:40px;
        }

        span {
            .font-type-1();
            .color-main;

            display:inline-block;
            float:left;
            margin-top:10px;
            font-size:70px;
            line-height:40px;
        }

        &.style-2 {

            span {
                color:@color-common - #111111;
            }
        }
    }
}
