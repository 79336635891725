// block-team-style-1
.team-style-01 {
    .image {
        display: inline-block;
        margin-bottom: 35px;
        position: relative;
        overflow: hidden;
        .easing-animation();
        
        img {
            max-width: 100%;
        }
    }
    
    //hover
    .image:hover {
        .easing-animation();
        
        .overlay {
            opacity: 1;
            .easing-animation();
        }
        
        .social-01 li {
            transform: translateY(0);
        }
    }
    
    //job
    .job {
        .color-main;
        .italic;
        .font-type-2;
    }
    
    //name
    .name {
        margin-bottom: 0;
    }
    
    .overlay {
        background-color: fade(@color-light , 70%);
        opacity: 0;
        top: 0;
        .easing-animation();
    }
    
    .social-01 {
        a {
            border-radius: 50%;
            font-size: 15px;
            .square(30px);
            
            &:hover {
                background-color: @color-main;
                
                i:before {
                    color: @color-light;
                }
            }
            
            i {
                line-height: 30px;
                
                &:before {
                    color: @color-heading;
                }
            }
        }
        
        li {
            transform: translateY(-40px);
            .easing-animation();
            
            &:nth-child(1) {
                transition-delay: 0s;
            }
            
            &:nth-child(2) {
                transition-delay: 0.1s;
            }
            
            &:nth-child(3) {
                transition-delay: 0.2s;
            }
            
            &:nth-child(4) {
                transition-delay: 0.3s;
            }
        }
    }
}
/* TEAM STYLE 2 */
.team-style-02 {
    margin-bottom: 40px;
    
    // padding: 20px;
    //image
    
    .image {
        display: inline-block;
        margin-bottom: 35px;
        position: relative;
        .easing-animation();
        
        img {
            max-width: 100%;
        }
    }
    
    //hover
    .image:hover {
        .easing-animation();
        
        .overlay {
            opacity: 1;
            .easing-animation();
        }
        
        .social-01 li {
            transform: translateY(0);
        }
    }
    
    //job
    .job {
        .color-main;
        .italic;
        .font-type-2;
    }
    
    //name
    .name {
        margin-bottom: 0;
    }
    
    .overlay {
        opacity: 0;
        .overlay(fade(@bgc-light, 70%););
    }
    
    .social-01 {
        a {
            background-color: @color-light;
            border-radius: 50%;
            font-size: 15px;
            .square(30px);
            
            &:hover {
                background-color: @color-main;
                
                i:before {
                    color: @color-light;
                }
            }
            
            i {
                line-height: 30px;
                
                &:before {
                    color: @color-heading;
                }
            }
        }
        
        li {
            padding: 0 10px;
            transform: translateY(-40px);
            .easing-animation();
            
            &:nth-child(1) {
                transition-delay: 0s;
            }
            
            &:nth-child(2) {
                transition-delay: 0.1s;
            }
            
            &:nth-child(3) {
                transition-delay: 0.2s;
            }
            
            &:nth-child(4) {
                transition-delay: 0.3s;
            }
        }
    }
}
/* TEAM STYLE 3 */
.team-section-03 {
    .image {
        display: inline-block;
        .easing-animation();
        
        &:hover {
            .easing-animation();
            
            .overlay {
                opacity: 1;
                .easing-animation();
                
                .social-01 li {
                    transform: translateX(0);
                }
            }
        }
        
        .overlay {
            background-color: rgba(51, 51, 51, .9);
            left: 50%;
            line-height: 0;
            max-height: 210px;
            
            // position: absolute;
            max-width: 310px;
            opacity: 0;
            padding: 40px 70px;
            top: 50%;
            transform: translate(-50%, -50%);
            .color-light;
            
            .icon {
                font-size: 70px;
                .color-main-2;
            }
            
            .job {
                margin: 0;
                .italic;
            }
            
            .name {
                margin-bottom: 15px;
                .color-light;
            }
            
            .social-01 {
                a {
                    margin: 0 10px;
                    .easing-animation();
                    
                    &:hover i:before {
                        .color-light;
                        .easing-animation();
                    }
                }
                
                li {
                    transform: translateX(-200px);
                    .easing-animation();
                    
                    &:nth-child(1) {
                        transition-delay: 0s;
                    }
                    
                    &:nth-child(2) {
                        transition-delay: 0.1s;
                    }
                    
                    &:nth-child(3) {
                        transition-delay: 0.2s;
                    }
                    
                    &:nth-child(4) {
                        transition-delay: 0.3s;
                    }
                }
            }
        }
        
        img {
            max-width: 100%;
            position: relative;
        }
    }
}