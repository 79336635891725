.shortcode-parallax-1 {
    padding-top: 210px;
    padding-bottom: 210px;
    
    .content {
        img {
            margin-left: auto;
            margin-right: auto;
            width: 300px;
            margin-bottom: 38px;
        }

        p {
            font-size: @fz-common-2 + 1;
            margin-bottom: 0;
            .color-light;
            .font-serif;
        }
    }

    @media @xs-max {
        padding-top: 100px;
        padding-bottom: 100px;
        
        .content {
            img {
                width: 250px;
            }

            p {
                .fz-common-3;
            }
        }
    }
}

.shortcode-parallax-2 {
    padding-top: 210px;
    padding-bottom: 210px;
    
    .content {
        .heading-icon-2 {
            .heading-icon-2("\e2d1"; 68px; @color-light;);

            &:after {
                font-style: normal;
                margin-top: 10px;
            }
        }

        p {
            font-size: @fz-heading-2;
            margin-bottom: 0;
            .font-serif;
        }
    }
    
    @media @xs-max {
        padding-top: 100px;
        padding-bottom: 100px;
        .content {
            p {
                .fz-common-3;
            }
        }
    }
}
