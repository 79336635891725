@import 'variables';
/* TABLE CONTENT */
/* 1.Component */
/* ____Typography */
/* ____Color */
/* ____Utilities */
/* ____Boostrap Extent */
/* ____Section */
/* ____Input */
/* ____Button */
/* ____Social */
/* ____Separator */
/* 2.Custom Plugin */
/* ____Flexslider Custom */
/* ____Slick Theme Custom */
/* ____Magnific Popup Custom */
/* 3.Site Module */
/* ____Navigator */
/* ____Sticky Navigator */
/* ____Header */
/* ____Home */
/* ____Slip Page */
/* ____Services */
/* ____Cover Box */
/* ____Widget */
/* ____Team */
/* ____Blog */
/* ____Testimonials */
/* ____Call To Action */
/* ____Accordion */
/* ____Portfolio */
/* ____Blog Homepage */
/* ____Post Detail*/
/* ____Portfolio Special */
/* ____Tab */
/* ____Count */
/* ____Page */
/* ____Footer */
/* ____Shop Slider */
/* ____Shop */
/* ____Quote */
/* ____Product */
/* ____Brand Slider */
/* ____Portfolio Slider */
/* ____Highlights */
/* ____Message Box */
/* ____Pricing Table */
/* ____Block Quotes */
/* ____Dropcaps*/
/* ____Shortcode Button */
/* ____Shortcode Parallax */
/* ____Index Demo Page */


/* =============== component ===============*/
/* Typography */
@import 'component/typo';
/* Color */
@import 'component/color';
/* Utilities */
@import 'component/util';
/* Bootsrap Extent */
@import 'component/bootsrap-extend';
/* Section */
@import 'component/section';
/* Input */
@import 'component/input';
/* Button */
@import 'component/button';
/* Social */
@import 'component/social';
/* Modals */
@import 'component/modals';
/* Separator */
@import 'component/separator';
/* Flexslider Custom */
@import 'component/flexslider-custom';
/* Slick Theme Custom */
@import 'component/slick-theme-custom';
/* Magnific Popup Custom */
@import 'mfp-custom';
/* =============== module ===============*/

/* Navigator */
@import 'module/nav';
/* Header */
@import 'module/header';
/* Home */
@import 'module/home';
/* Navigator And Home (Combine) */
@import 'module/nav-and-home';
/* Sticky Navigator */
@import 'module/sticky-nav';
/* Slip Page */
@import 'module/slip-page';
/* Services */
@import 'module/service';
/* Cover Box */
@import 'module/coverbox';
/* Widget */
@import 'module/widget';
/* Team */
@import 'module/team';
/* Blog */
@import 'module/blog';
/* Testimonials */
@import 'module/testimonials';
/* Call To Action */
@import 'module/call-to-action';
/* Accordion */
@import 'module/accordion';
/* Portfolio */
@import 'module/portfolio';
/* Blog Homepage */
@import 'module/blog-homepage';
/* Post Detail*/
@import 'module/post-detail';
/* Portfolio Special */
@import 'module/portfolio-special';
/* Tab */
@import 'module/tab';
/* Count */
@import 'module/count';
/* Page */
@import 'module/page';
/* Footer */
@import 'module/footer';
/* Shop Slider */
@import 'module/shop-slider';
/* Shop */
@import 'module/shop';
/* Shop Homepage */
@import 'module/shop-homepage';
/* Product */
@import 'module/product';
/* Shop detail */
@import 'module/shop-detail';
/* Shop category */
@import 'module/shop-category';
/* Shop Cart*/
@import 'module/shop-cart';
/* Check Out */
@import 'module/checkout';
/* Shop Successfully */
@import 'module/shop-successfully';
/* Quote */
@import 'module/quote';
/* Brand Slider */
@import 'module/brand-slider';
/* Portfolio Slider */
@import 'module/portfolio-slider';
/* Highlights */
@import 'module/highlights';
/* Message Box */
@import 'module/messagebox';
/* Pricing Table */
@import 'module/pricing-table';
/* Block Quotes */
@import 'module/blockquotes';
/* Dropcaps*/
@import 'module/dropcaps';
/* Progressbar*/
@import "module/progressbar";
/* Piechart*/
@import "module/piechart";
/* Shortcode Button */
@import 'module/shortcode-button';
/* Shortcode Parallax */
@import 'module/shortcode-parallax';
/* Index Demo Page */
@import 'module/index';


.slip-control-nav #fp-nav ul li a span, .slip-control-nav .fp-slidesNav ul li a span {
  background: #fff;
}

.slip-home .cell-middle {
  background: url('../images/logo_transparent.png') center center no-repeat;
  background-size: 60%;
}

@media (min-width: 992px) {
  .main-nav-1.slip-page .nav-main-menu > li > a {
      line-height: 100px;
      color: #fff;
      font-weight: bold;
  }

  .main-nav-1.slip-page .nav-main-menu > li:hover > a,
  .main-nav-1.slip-page .nav-main-menu > li.current-menu-item > a {
    color: #fff;
    text-decoration: underline;
  }

}

.background-banner {
  background-position: left center;
  background-size: contain;
}


@media (max-width: 991px) {
  .item-content-wrapper {
   background: none !important;
  }

  .slip-section .slip-bg-image {
    background-position: 20%;
  }
}

.nav-main-menu:not(.left-menu) a {
  font-size: 16px;
}

.slip-portfolio .item-content-wrapper .item-content .paragraph,
.slip-about .item-content-wrapper .item-content .paragraph,
.slip-about .item-content-wrapper .item-content p {

  font-family: 'hanken';
  font-style: normal;
  font-size: 15px;
}

.slip-about .item-content-wrapper .item-content p {
  font-size: 15px;
}

.item-content.wide {

}

.slip-center .item-content {
  text-align: center;
}

.slip-dark .item-content-wrapper, .slip-dark {
  background: #bdbdbd !important;
}

.slip-about, .slip-service {

  ul li {
    text-indent: -11px;
    padding-left: 11px;
    font-size: 15px;
  }

  ul li::before {
    content: "- "

  }

h1 {
  margin: 10px 0;
  font-size: 36px;
}

  h4 {
    margin: 10px 0;
  }
}

.help-block {
  float: left;
  clear: both;
}

#rsvpform {
  input, textarea {
    width: 100%;
  }

  ul li {
    font-size: 13px;
  }

  .radio {
    input {
      width: auto;
      margin: 0 10px;
    }
  }
}

.underline {
  text-decoration: underline;
}
