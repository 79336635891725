.category-sorting {
    padding: 10px 0;

    > p {
        float: left;
        margin-bottom: 0;
        line-height: 44px;
        margin-right: 30px;
    }

    select {
        float: left;
        font-weight: 600;
        height: 44px;
        border: 1px solid #ddd;
        max-width: 100%;
        padding: 0 15px;
    }
}

.shop-category-side-bar {
    .category-product-wrapper {
        margin-bottom: 50px;

        h5 {
            margin-bottom: 40px;
        }

        .widget.category-product {
            margin-bottom: 0;
        }
    }

    .price-filter {
        margin-bottom: 75px;

    }
}

.shop-category-page {
    .pagination-2 {
        padding-top: 25px;
        padding-bottom: 105px;
    }
}

.shop-category-caro-page {
    .pagination-2 {
        padding-top: 70px;
        padding-bottom: 65px;
    }
}