.dropcap-1 {
    span.first-letter {
        border:1px solid;
        font-size: 38px;
        display: inline-block;
        width: 56px;
        height: 56px;
        float: left;
        font-weight: 700;
        line-height: 56px;
        margin-right: 15px;
        text-align: center;
    }

    &.style-1 {
        span.first-letter {
            .bgc-main;
            .color-light;
            border-color: @color-main;
        }
    }


    &.style-2 {
        span.first-letter {
            .color-light;
            border-color: @color-common;
            background-color: @color-common;
        }
    }

    &.style-3 {
        span.first-letter {
            border-color: @color-common + #222;
            color: @color-common + #222;
        }
    }
}

.dropcap-2 {
    span.first-letter {
        border:1px solid;
        font-size: 38px;
        display: inline-block;
        width: 56px;
        height: 56px;
        float: left;
        font-weight: 700;
        line-height: 56px;
        margin-right: 15px;
        text-align: center;
        border-radius: 50%;
    }

    &.style-1 {
        span.first-letter {
            .bgc-main;
            .color-light;
            border-color: @color-main;
        }
    }


    &.style-2 {
        span.first-letter {
            .color-light;
            border-color: @color-common;
            background-color: @color-common;
        }
    }

    &.style-3 {
        span.first-letter {
            border-color: @color-common + #222;
            color: @color-common + #222;
        }
    }
}

.dropcap-3 {
    span.first-letter {
        float: left;
        font-size: 55px;
        line-height: 55px;
        font-weight: 700;
        margin-right: 15px;
    }

    &.style-1 {
        span.first-letter {
            .color-main;
        }
    }


    &.style-2 {
        span.first-letter {
            color: @color-common;
        }
    }

    &.style-3 {
        span.first-letter {
            color: @color-common + #222;
        }
    }
}