.shop-homepage-other-links {
    text-align: center;
    
    .border-btn, .normal-btn {
        padding-left: 15px;
        padding-right: 15px;
        max-width: 270px;
        width: 100%;
    }

    .border-btn {
        margin-right: 30px;
    }

    br {
        display: none;
    }

    @media @xs-max {
        br {
            display: block;
        }

        .border-btn {
            margin-right: 0;
            margin-bottom: 30px;
        }
    }
}

.shop-homepage-1-category-links-wrapper {
    font-size: 0;
}

.shop-homepage-1-category-links {
    .clearfix();
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid @color-lightgray;
    display: inline-block;

    > a {
        .font-type-1();
        font-size: 12px;
        display: inline-block;
        margin-right: 20px;
        margin-left: 20px;

        &.active, &:hover {
            .color-main;
        }
    }
}

.shop-homepage-banner-wrapper {
    max-width: 370px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    
    .shop-homepage-banner {
        position: relative;

        img {
            width: 100%;
        }

        .overlay {
            .overlay(fade(@bgc-light,70%));
            opacity: 0;

            i {
                // display: block;
                font-size: 60px;
                .color-main;
                display: inline-block;
                height: 60px;
                line-height: 1;

                &:before {
                    display: inline-block;
                    line-height: 1;
                }
            }

            h5 {
                margin-bottom: 0;
            }
        }

        &:hover {
            .overlay {
                opacity: 1;
            }
        }
    }
}

.shop-homepage-banner-wrapper-2 {
    @media @xs-max {
        &:not(:last-of-type) {
            margin-bottom: 30px;
        }
    }
}

.shop-homepage-banner-2 {
    position: relative;
    background-position: 50% 0;
    background-repeat: no-repeat;
    background-size: cover;
    height: 195px;
    width: 100%;

    .overlay {
        .overlay(fade(@bgc-dark,70%));
        opacity: 0;
    }

    &:hover {
        .overlay {
            opacity: 1;
        }
    }
}

.shop-side-cart-fixed {
    position: fixed;
    z-index: 1450;
    top: 150px;
    right: 20px;
    height: 44px;
    width: 44px;
    .bgc-gray;
    text-align: center;
    line-height: 44px;

    .shop-side-cart {
        display: inline-block;
        position: relative;
        top: -4px;
        color: #999;
        line-height: 28px;
        vertical-align: middle;
        height: 30px;

        &:before {
            width: 22px;
            height: 18px;
            text-align: center;
            position: absolute;
            display: block;
            line-height: 18px;
            top: 0px;
            left: 0px;
            content: attr(data-content);
            font-size: 11px;
            .color-main;
            .font-type-1();
        }
        i {
            font-size: 18px;
            display: inline-block;
            line-height: 18px;
            height: 18px;
            vertical-align: bottom;
        }
    }

    @media @sm-max {
        right: 0;
    }
}

