.color-main {
    color: @color-main;
}

.color-main-2 {
    color: @color-main-2;
}

.color-light {
    color: @color-light;
}

.color-gray {
    color: @color-gray;
}

.color-lightgray {
    color: @color-lightgray;
}

.color-dark {
    color: @color-heading;
}

.color-common {
    color: @color-common;
}

// ===
.bgc-main {
    background-color: @color-main;
}

.bgc-dark {
    background-color: @bgc-dark;
}

.bgc-light {
    background-color: @bgc-light;
}

.bgc-gray {
    background-color: @bgc-gray;
}

//==
.border-bottom {
  border-bottom:solid 1px @color-lightgray2;
}

.border-top {
  border-top:solid 1px @color-lightgray2;
}