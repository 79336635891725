.shortcode-button {
    @media (max-width: 479px) {
        .normal-btn {
            &.btn-size-1 {
                padding: 14px 88px;
            }

            &.btn-size-2 {
                padding: 14px 75px;
            }

            &.btn-size-3 {
                padding: 14px 65px;
            }
        }

        .border-btn {
            &.btn-size-1 {
                padding: 13px 87px;
            }

            &.btn-size-2 {
                padding: 13px 74px;
            }

            &.btn-size-3 {
                padding: 13px 64px;
            }
        }
    }
}