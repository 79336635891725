// Brand Carousel (Multiple Items - Slider)
// ======

.brand-carousel-wrapper {
    background-color: #939e98;
}

.brand-carousel {
    
    .brand-item .item-image {
        text-align: center;
        
        > a {
            display: inline-block;
            opacity: 1;
            
            &:hover {
                opacity: 0.7;
            }
        }
    }
}