/* Slider */
.slick-list {
    .slick-loading & {
        background: #fff url("../images/ajax-loader.gif") center center no-repeat;
    }
}

/* Arrows */
.slick-prev,
.slick-next {
    background: transparent;
    border: none;
    color: transparent;
    cursor: pointer;
    display: block;
    font-size: 0;
    line-height: 0;
    padding: 0;
    position: absolute;
    
    &.slick-disabled:before {
        opacity: 0.25;
    }
    
    // outline: none;
    &:hover,
    &:focus {
        
        // outline: none;
        background: transparent;
        color: transparent;
    }
}

.slick-prev:before,
.slick-next:before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    line-height: 1;
    .easing-animation();
}

.slick-prev {
    &:before {
        content: "";
    }
}

.slick-next {
    &:before {
        content: "";
    }
}

/* Dots */
.slick-slider {
    
    // margin-bottom: 30px;
}

.slick-dots {
    display: block;
    
    // bottom: -45px;
    list-style: none;
    padding: 0;
    position: absolute;
    text-align: center;
    
    // width: 100%;
    li {
        cursor: pointer;
        display: inline-block;
        
        // height: 20px;
        // width: 20px;
        // margin: 0 5px;
        padding: 0;
        position: relative;
        
        &.slick-active button:before {
            
            // color: $slick-dot-color-active;
            // opacity: $slick-opacity-default;
        }
        
        button {
            background: transparent;
            border: 0;
            color: transparent;
            
            // padding: 5px;
            cursor: pointer;
            display: block;
            
            // height: 20px;
            // width: 20px;
            // outline: none;
            font-size: 0;
            line-height: 0;
            
            &:before {
                -moz-osx-font-smoothing: grayscale;
                -webkit-font-smoothing: antialiased;
                content: "";
                
                // content: $slick-dot-character;
                // width: 20px;
                // height: 20px;
                // font-family: $slick-font-family;
                // font-size: $slick-dot-size;
                // line-height: 20px;
                // text-align: center;
                // color: $slick-dot-color;
                // opacity: $slick-opacity-not-active;
                left: 0;
                position: absolute;
                top: 0;
            }
            
            &:hover,
            &:focus {
                
                // outline: none;
                &:before {
                    
                    // opacity: $slick-opacity-on-hover;
                }
            }
        }
    }
}