.separator-1 {
    width: 100%;
    height: 12px;
    background-color: @bgc-dark + #111;
}

.separator-2 {
    width: 100%;
    height: 8px;
    background-color: @color-lightgray;
}

.separator-3 {
    width: 100%;
    height: 4px;
    background-color: @color-lightgray;
}

.separator-4 {
    width: 100%;
    height: 2px;
    background-color: @color-lightgray;
}

.separator-5 {
    width: 100%;
    height: 1px;
    background-color: @color-lightgray;
}

.separator-6 {
    width: 100%;
    height: 1px;
    background-color: @color-lightgray + #111;
}