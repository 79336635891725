/*
 * jQuery FlexSlider v2.5.0
 * http://www.woothemes.com/flexslider/
 *
 * Copyright 2012 WooThemes
 * Free to use under the GPLv2 and later license.
 * http://www.gnu.org/licenses/gpl-2.0.html
 *
 * Contributing author: Tyler Smith (@mbmufffin)
 * 
 */
/* ====================================================================================================================
 * RESETS
 * ====================================================================================================================*/
.flex-container a:hover,
.flex-slider a:hover,
.flex-container a:focus,
.flex-slider a:focus {
    outline: none;
}

.slides,
.slides > li,
.flex-control-nav,
.flex-direction-nav {
    list-style: none;
    margin: 0;
    padding: 0;
}

.flex-pauseplay span {
    text-transform: capitalize;
}
/* ====================================================================================================================
 * BASE STYLES
 * ====================================================================================================================*/
.flexslider-custom {
    .slides {
        &:after {
            clear: both;
            content: "";
            display: block;
            height: 0;
            line-height: 0;
            visibility: hidden;
        }
        
        > li {
            -webkit-backface-visibility: hidden;
            display: none;
        }
        
        img {
            display: block;
            width: 100%;
        }
    }
}

html[xmlns] .flexslider-custom .slides {
    display: block;
}

* html .flexslider-custom .slides {
    height: 1%;
}

.no-js .flexslider-custom .slides > li:first-child {
    display: block;
}
/* ====================================================================================================================
 * DEFAULT THEME
 * ====================================================================================================================*/
.flexslider-custom {
    position: relative;
    zoom: 1;
    
    .slides {
        zoom: 1;
        
        img {
            height: auto;
        }
    }
}

.flex-viewport {
    max-height: 2000px;
    .easing-animation();
    
    .loading & {
        max-height: 300px;
    }
}

.carousel li {
    margin-right: 5px;
}

.flex-direction-nav {
    *height: 0;
    
    .flex-disabled {
        cursor: default;
        filter:alpha(opacity=0);
        opacity: 0!important;
    }
    
    a {
        cursor: pointer;
        display: block;
        height: 40px;
        margin: -20px 0 0;
        overflow: hidden;
        position: absolute;
        width: 40px;
        z-index: 10;
        
        &:before {
            content: "";
            display: inline-block;
            .easing-animation();
        }
    }
}

.flex-pauseplay a {
    bottom: 5px;
    color: #000;
    cursor: pointer;
    display: block;
    height: 20px;
    left: 10px;
    opacity: 0.8;
    overflow: hidden;
    position: absolute;
    width: 20px;
    z-index: 10;
    
    &:before {
        content: '';
        display: inline-block;
        font-size: 20px;
    }
}

.flex-control-nav {
    bottom: -40px;
    position: absolute;
    text-align: center;
    width: 100%;
    
    li {
        *display: inline;
        display: inline-block;
        margin: 0 6px;
        zoom: 1;
    }
}

.flex-control-paging li a {
    background: #666;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    display: block;
    height: 11px;
    text-indent: -9999px;
    width: 11px;
    
    &.flex-active {
        background: #000;
        background: rgba(0, 0, 0, 0.9);
        cursor: default;
    }
    
    &:hover {
        background: #333;
        background: rgba(0, 0, 0, 0.7);
    }
}

.flex-control-thumbs {
    margin: 5px 0 0;
    overflow: hidden;
    position: static;
    
    .flex-active {
        cursor: default;
        opacity: 1;
    }
    
    img {
        -moz-transition: all 1s ease;
        -ms-transition: all 1s ease;
        -o-transition: all 1s ease;
        -webkit-transition: all 1s ease;
        cursor: pointer;
        display: block;
        height: auto;
        opacity: 0.7;
        transition: all 1s ease;
        width: 100%;
        
        &:hover {
            opacity: 1;
        }
    }
    
    li {
        float: left;
        margin: 0;
        width: 25%;
    }
}