.count-section-01 {
    letter-spacing: -4px;
    text-align: center;

    &.style-2 {
        background-color: @color-lightgray + #111;
    }

    &.style-3 {
        .timer {
            .color-main;
        }

        .counter > p {
            color: @color-gray - #222;
        }
    }

    &.style-4 {
        &:before {
            content: "";
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            position: absolute;
            background-color: transparent;
            z-index: -1;
        }
        position: relative;
        z-index: 0;

        .timer {
            .color-light;
        }

        .counter{
          border: 1px solid @color-gray;
          border-bottom: none;
          border-top: none;
        }

        .counter > p {
            .color-light;
        }
        .counter-group {
            z-index: 500;
        }
    }
    .count-wrapper {
        @media @lg {
            float: left;
            width: 20%; 
            margin-right: -1px;
        }
        
    }

    .counter {
        border: 1px solid @color-lightgray2;
        border-bottom: none;
        border-top: none;
        letter-spacing: -4px;
        display: inline-block;
        text-align: center;
        width: 100%;
        max-width: 235px;

        .timer {
            display: block;
            font-size: 60px;
            line-height: 60px;
            text-align: center;

            @media @md-max {
                font-size: 50px;
            }
        }

        p {
            display: block;
            font-size: 16px;
            letter-spacing: 0;
            line-height : 1;
            margin-bottom : 0;
            font-style : italic;
            .color-main;
            .font-type-2();
        }
    }
}