.highlights {

    span {
        .bgc-main;
        .color-light;

        padding:4px;
    }

    &.style-2 {

        span {
            background-color:@color-lightgray - #111;
            color:@color-heading;
        }
    }
}
