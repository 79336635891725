header {
    .clearfix();
    &.site-header{
        position: relative;
    }
}

.common-static-header,.portfolio-page-header{
    position : relative;
    padding : 90px 0;
    .parallax-bg{
        .parallax-bg(fade(#333, 30%));
        background-size : cover;
        background-repeat: repeat-y;
    }

    h1{
        .color-light;
        margin-bottom : 0;
    }
}

.portfolio-page-header{
    padding: 120px 0;
}

//Page header
.homepage-blog-header{
    padding-top: 60px;
}
.homepage-classic-header{
    padding: 50px 0;
}
.homepage-classic-2-header{
    padding-bottom: 25px;
}